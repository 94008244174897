<template>
  <div
    id="DetailCarCatalog"
    class="page-container-table"
  >
    <b-tab
      active
      title="Left"
    >
      <b-tabs
        vertical
        nav-wrapper-class="nav-vertical"
      >
        <b-tab
          active
          title="Giới thiệu"
          @click="reloadDataRoot(1, groupFatherId)"
        >
          <gioi-thieu
            :d-group="1"
            :d-group-father="groupFatherId"
          />
        </b-tab>
        <b-tab
          title="Công nghệ"
          @click="reloadDataRoot(2, groupFatherId)"
        >
          <cong-nghe
            :d-group="2"
            :d-group-father="groupFatherId"
          />
        </b-tab>
        <b-tab
          title="Ngoại thất"
          @click="reloadDataRoot(3, groupFatherId)"
        >
          <ngoai-that
            :d-group="3"
            :d-group-father="groupFatherId"
          />
        </b-tab>
      </b-tabs></b-tab></div>
</template>
<script>
// eslint-disable-next-line import/no-unresolved
import {
  BButton, BTabs, BTab, BCardText, BRow, BCol, BFormTextarea, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import NQuillEditor from '@/views/ndev-component/NQuillEditor.vue'
import GioiThieu from '@/views/car-manager/car-catalog/pages/components/Overview/GioiThieu.vue'
import CongNghe from '@/views/car-manager/car-catalog/pages/components/Overview/CongNghe.vue'
import NgoaiThat from '@/views/car-manager/car-catalog/pages/components/Overview/NgoaiThat.vue'

export default {
  components: {
    NgoaiThat,
    GioiThieu,
    BCardCode,
    NQuillEditor,
    CongNghe,
    BButton,
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    BFormTextarea,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    dStep: {
      type: Number,
      default: 0,
    },
    dStepStructure: {
      type: Object,
      default: _ => {
      },
    },
  },
  data() {
    return {
      idModalNNewsDataGroup: 'idModalNNewsDataGroup',
      groupSend: 1,
      groupFatherId: 1,
    }
  },
  created() {
    this.dStepStructure = []
  },
  // eslint-disable-next-line vue/order-in-components
  // computed: {
  //   ...mapState('business', ['dataEdit', 'alert']),
  // },
  methods: {
    ...mapActions('libraryStore', ['libraryStore_action_resetQuillContent']),
    // handler_insert_step(val) {
    //   this.dStepStructure.push({
    //     id: this.dStep === 0 ? 1 : this.dStep + 1,
    //     idNewsData: val,
    //   })
    //   this.dStep += 1
    // },
    setActive(tabActive) {
      this.activeItem = tabActive
    },
    handler_open_news_data_group(val) {
      this.groupSend = val
      this.$bvModal.show(this.idModalNNewsDataGroup)
    },
    handleCloseModalNewsDataGroup(val) {
      const iNumber = this.dStep === 0 ? 1 : this.dStep + 1
      this.dStepStructure.push({
        id: iNumber,
        sNumber: iNumber === 1 ? iNumber : Math.max(...this.dStepStructure.map(o => o.sNumber)) + 1,
        idNewsData: val.position,
      })
      this.dStep += 1
      this.$bvModal.hide(this.idModalNNewsDataGroup)
    },
    quillChangeData(val) {
      this.overview_content = val
    },
    handler_delete_step(val) {
      const iDStepStructure = this.dStepStructure
      this.dStepStructure = []
      for (let i = 0; i < iDStepStructure.length; i++) {
        if (iDStepStructure[i].sNumber !== val.sNumber) {
          this.dStepStructure.push({
            id: iDStepStructure[i].id,
            sNumber: iDStepStructure[i].sNumber,
            idNewsData: iDStepStructure[i].position,
          })
        }
      }
    },
  },
}
</script>

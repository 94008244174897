<template>
  <b-row>
    <b-col
      lg="12"
      md="12"
      sm="12"
      xl="12"
      xs="12"
    >
      <c-c-list
        :c-c-list_id="'ccList_GioiThieu'"
        :c-c-add-new_-group="1"
        :c-c-group="1"
        :c-c-group-father="1"
        :nv3-modal-delete-id="'delete_ccList_GioiThieu'"
        :library-modal-id="'libraryModalId_GioiThieu'"
        :nv3-title-id="'nv3TitleId_GioiThieu'"
        :nv3-content-id="'nv3ContentId_GioiThieu'"
        :nv3-image-id="'nv3ImageId_GioiThieu'"
        :nv3-content-library-id="'nv3ContentLibraryId_GioiThieu'"
        :quill-content-v3-id="'quillContentV3Id_GioiThieu'"
        :nv3-row-data-id="'nv3RowDataId_GioiThieu'"
        :nv3-multi-small-image-library-view-id="'nv3MultiSmallImageLibraryViewId_GioiThieu'"
        :nv3-multi-small-image-id="'nv3MultiSmallImageId_GioiThieu'"
        :nv3-multi-small-image-library-id="'nv3MultiSmallImageLibraryId_GioiThieu'"
        :quill-multi-small-image-v3-id="'nv3MultiSmallImageQuillId_GioiThieu'"
      />
    </b-col>
  </b-row>
</template>
<script>
// eslint-disable-next-line import/no-unresolved
import {
  BButton, BCardText, BCol, BFormInput, BFormTextarea, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import NQuillEditor from '@/views/ndev-component/NQuillEditor.vue'
import NNewsData from '@/views/ndev-component/news-data-manager/NNewsData.vue'
import NNewsDataGroup from '@/views/ndev-component/news-data-manager/NNewsDataGroup.vue'
import NImageFullScreen from '@/views/ndev-component/news-data-manager/NImageFullScreen.vue'
import NTitle from '@/views/ndev-component/news-data-manager/NTitle.vue'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import ConstantsApi from '@/views/car-manager/car-catalog/constants/ConstantsApi'
import NCKEditor from '@/views/ndev-component/NCKEditor.vue'
import CCList from '@/views/car-manager/car-catalog/pages/components/CCList.vue'

export default {
  components: {
    CCList,
    NCKEditor,
    ConstantsApi,
    NTitle,
    NImageFullScreen,
    NNewsData,
    BCardCode,
    NQuillEditor,
    NNewsDataGroup,
    BButton,
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    BFormTextarea,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    dStep: {
      type: Number,
      default: 0,
    },
    dStepStructure: {
      type: Object,
      default: _ => {
      },
    },
    dDataRoot: {
      type: Object,
      default: _ => {
      },
    },
    dGroup: {
      type: Number,
      default: 1,
    },
    dGroupFather: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('libraryStore', ['libraryStore_state_CarDetailModel']),
  },
  methods: {
    ...mapActions('libraryStore', ['libraryStore_action_resetQuillContent']),
    async handler_overview_button(type) {
      if (type === 1) {
        // Làm mới dữ liệu

      } else {
        this.loading = true
        const sendModel = []
        sendModel.push(this.$refs.OverviewImageFullScreen.modelSend)
        sendModel.push(this.$refs.OverviewTitle.modelSend)
        sendModel.push(this.$refs.OverviewContent.modelSend)
        const paySend = {
          carCatalogId: this.$route.params.id,
          model: sendModel,
        }
        const iResult = await NCoreHelper.excutePUT(this, ConstantsApi.UPDATE_DETAIL_CAR_CATEGORY, paySend, 'Cập nhật dữ liệu thành công')
        // ==> Cập nhật dữ liệu thành công => Load lại dữ liệu
        this.loading = false
        this.$emit('reloadDataRoot', this.groupId, this.groupFatherId)
      }
    },
    setActive(tabActive) {
      this.activeItem = tabActive
    },
    handler_open_news_data_group(val) {
      this.groupSend = val
      this.$bvModal.show(this.idModalNNewsDataGroup)
    },
    handleCloseModalNewsDataGroup(val) {
      const iNumber = this.dStep === 0 ? 1 : this.dStep + 1
      this.dStepStructure.push({
        id: iNumber,
        sNumber: iNumber === 1 ? iNumber : Math.max(...this.dStepStructure.map(o => o.sNumber)) + 1,
        idNewsData: val.position,
      })
      this.dStep += 1
      this.$bvModal.hide(this.idModalNNewsDataGroup)
    },
    quillChangeData(val) {
      this.overview_content = val
    },
    getDataRoot(val) {
      const iResult = this.dDataRoot.model.find(x => x.position === val)
      return iResult
    },
  },
}
</script>

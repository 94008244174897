<template>
  <div
    :id="CCList_id"
    class="page-container-table"
  >
    <!--phần header-->
    <n-button-header
      :contentBtnAdd="'Thêm mới'"
      :hide-multi-add="true"
      :showBtnMultiDelete="false"
      @clickAdd="handleOpenModal(1, null)"
      @search="search($event)"
    />
    <!--Phần bảng-->
    <vue-good-table
      ref="user-table"
      :columns="columns"
      :rows="dataRows || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :sort-options="{
        enabled: false,
      }"
      style-class="vgt-table"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column:Tên loại xe-->
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <b-media>
            <template #aside>
              <user-avatar :data="props.row" />
            </template>
            <span class="font-weight-bold text-name">{{ props.row.name }}</span>
          </b-media>
        </span>

        <!--   Column:Trạng thái -->
        <span v-else-if="props.column.field === 'statusName'">
          <n-form-status
            :status-id="props.row.statusId"
            :status-name="props.row.statusName"
          />
        </span>
        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'actionFunction'">
          <span
            @click="handleOpenModal(2, props.row)"
          >
            <feather-icon
              class="text-body"
              icon="EditIcon"
              size="18"
            />
          </span>
          <span
            class="ml-2"
            @click="handleOpenModal(3, props.row)"
          >
            <feather-icon
              class="text-body"
              icon="Trash2Icon"
              size="18"
            />
          </span>
          <span
            class="ml-2"
            @click="handleOpenModal(4, props.row)"
          >
            <feather-icon
              class="text-body"
              icon="EyeIcon"
              size="18"
            />
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :current-page="urlQuery.currentPage"
      :total-items="totalRecord"
      @pageClick="handlePageClick"
    />

    <!-- modal thêm mới -->
    <c-c-add-new
      :c-c-add-new_-group="CCAddNew_Group"
      :c-c-add-new_id="CCAddNew_id"
      @handleClick="handleClickAddNew"
    />
    <!--    Ảnh đại diện-->
    <n-v3-image
      :library-id="libraryModalId"
      :nv3-image-id="nv3ImageId"
      :nv3-image-type="Nv3ImageType"
      :root-data="nv3ImageData"
      @handClick="handClickSave"
    />
    <!--    Tiêu đề-->
    <n-v3-title
      :nv3-image-type="Nv3ImageType"
      :nv3-title-id="nv3TitleId"
      :nv3-title-place="'Nhập tiêu đề ...'"
      :nv3-title-required="true"
      :root-data="nv3TitleData"
      @handClick="handClickSave"
    />
    <!--Nội dung-->
    <n-v3-content
      :nv3-content-id="nv3ContentId"
      :nv3-content-library-id="Nv3ContentLibraryId"
      :nv3-content-place="''"
      :nv3-content-quill-id="quillContentV3Id"
      :nv3-content-required="true"
      :nv3-content-type="Nv3ImageType"
      :root-data="nv3ContentData"
      @handClick="handClickSave"
    />
    <!--Cột dữ liệu-->
    <n-v3-row-data
      :nv3-row-data-id="nv3RowDataId"
      :nv3-row-data-library-id="Nv3RowDataLibraryId"
      :nv3-row-data-place="''"
      :nv3-row-data-quill-id="quillRowDataV3Id"
      :nv3-row-data-required="true"
      :nv3-row-data-type="Nv3ImageType"
      :root-data="nv3RowDataData"
      @handClick="handClickSave"
    />
    <!--    Nhiều hình ảnh-->
    <n-v3-multi-small-image
      :nv3-multi-small-image-id="nv3MultiSmallImageId"
      :nv3-multi-small-image-library-id="Nv3MultiSmallImageLibraryId"
      :nv3-multi-small-image-place="''"
      :nv3-multi-small-image-quill-id="quillMultiSmallImageV3Id"
      :nv3-multi-small-image-required="true"
      :nv3-multi-small-image-type="Nv3ImageType"
      :nv3-multi-small-image-library-view-id="Nv3MultiSmallImageLibraryViewId"
      :root-data="nv3MultiSmallImageData"
      @handClick="handClickSave"
    />
    <!--     Modal xóa-->
    <modal-delete-no-param
      :content-tile="modalContent"
      :id-modal-delete-no-param="nv3ModalDeleteId"
      :type="modalType"
      @accept="handleClickDelete"
    />
  </div>
</template>

<script>
import { formatDateToDDMM } from '@core/utils/filter'
import {
  BBadge, BDropdown, BDropdownItem, BMedia,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import NFormStatus from '@/views/ndev-component/NFormStatus.vue'
import ModalDeleteNoParam from '@/views/ndev-component/ModalDeleteNoParam.vue'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import CCAddNew from '@/views/car-manager/car-catalog/pages/components/CCAddNew.vue'
import NV3Image from '@/views/ndev-component/componentV3/NV3Image.vue'
import NButtonHeader from '@/views/ndev-component/component/NButtonHeader.vue'
import ConstantsApi from '@/views/car-manager/car-catalog/constants/ConstantsApi'
import NV3Title from '@/views/ndev-component/componentV3/NV3Title.vue'
import NV3Content from '@/views/ndev-component/componentV3/NV3Content.vue'
import NV3RowData from '@/views/ndev-component/componentV3/NV3RowData.vue'
import NV3MultiSmallImage from '@/views/ndev-component/componentV3/NV3MultiSmallImage.vue'

export default {
  components: {
    NV3MultiSmallImage,
    NV3RowData,
    NV3Content,
    NV3Title,
    NButtonHeader,
    NV3Image,
    CCAddNew,
    NFormStatus,
    ModalDeleteNoParam,
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    BDropdown,
    BDropdownItem,
    ButtonComponent,
    ButtonAllHeader,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatDateToDDMM,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    CCList_id: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/prop-name-casing
    CCListKey: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/prop-name-casing
    CCAddNew_Group: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/prop-name-casing
    CCGroup: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/prop-name-casing
    CCGroupFather: {
      type: Number,
      default: 0,
    },
    nv3ImageData: {
      type: Array,
      default: _ => {
      },
    },
    nv3TitleData: {
      type: Array,
      default: _ => {
      },
    },
    nv3ContentData: {
      type: Array,
      default: _ => {
      },
    },
    nv3RowDataData: {
      type: Array,
      default: _ => {
      },
    },
    nv3MultiSmallImageData: {
      type: Array,
      default: _ => {
      },
    },
    // eslint-disable-next-line vue/prop-name-casing
    CCAddNew_id: {
      type: String,
      default: 'CCAddNew_id',
    },
    libraryModalId: {
      type: String,
      default: 'library_id',
    },
    nv3ImageId: {
      type: String,
      default: 'nv3ImageId',
    },
    nv3TitleId: {
      type: String,
      default: 'nv3TitleId',
    },
    nv3ContentId: {
      type: String,
      default: 'nv3ContentId',
    },
    nv3RowDataId: {
      type: String,
      default: 'nv3RowDataId',
    },
    nv3MultiSmallImageId: {
      type: String,
      default: 'nv3RowDataId',
    },
    Nv3MultiSmallImageLibraryViewId: {
      type: String,
      default: 'nv3RowDataId',
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3ImageType: {
      type: Number,
      default: 0,
    },
    nv3ModalDeleteId: {
      type: String,
      default: 'nv3ModalDeleteId',
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3ContentLibraryId: {
      type: String,
      default: 'Nv3ContentLibraryId',
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3RowDataLibraryId: {
      type: String,
      default: 'Nv3RowDataLibraryId',
    },
    // eslint-disable-next-line vue/prop-name-casing
    Nv3MultiSmallImageLibraryId: {
      type: String,
      default: 'Nv3RowDataLibraryId',
    },
    quillContentV3Id: {
      type: String,
      default: 'quillContentV3Id',
    },
    quillRowDataV3Id: {
      type: String,
      default: 'quillRowDataV3Id',
    },
    quillMultiSmallImageV3Id: {
      type: String,
      default: 'quillRowDataV3Id',
    },
  },
  data() {
    return {
      currentPage: 1,
      columns: [
        {
          label: 'Tên dữ liệu',
          field: 'name',
        },
        {
          label: 'Trạng thái',
          field: 'statusName',
        },
        {
          label: 'Chức năng',
          field: 'actionFunction',
          thClass: 'custom-action-table-class',
          tdClass: 'text-center',
        },
      ],
      urlQuery: {
        search: '',
        pageSize: 10,
        pageNumber: 1,
        carCatalogId: this.$route.params.id,
        groupId: this.CCGroup,
        groupFatherId: this.CCGroupFather,
      },
      modalContent: '', // thông báo xóa
      deleteIds: [], // ID xóa
      dataRows: [], // dữ liệu bảng
      totalRecord: 10, // tổng số trang
    }
  },
  // watch: {
  // },
  mounted() {
    // this.doFetchListUsers(this.urlQuery)
  },
  created() {
    this.fetchDataList()
  },
  methods: {
    // =========================hàm mới tạo ====================================//
    async fetchDataList() {
      const iResult = await NCoreHelper.excuteGET(this, ConstantsApi.GET_DETAIL_CAR_CATEGORY, this.urlQuery)
      this.dataRows = iResult.pageLists
      this.totalRecord = iResult.totalRecord
    },
    // -----phần tìm kiếm
    async search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        await this.fetchDataList()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        await this.fetchDataList()
      }
    },
    // -----phần phân trang
    async handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      await this.fetchDataList()
    },
    // ---- Open model -----
    async handleOpenModal(type, data) {
      switch (type) {
        case 1: {
          // Thêm mới
          this.$bvModal.show(this.CCAddNew_id)
          break
        }
        case 2: {
          // Cập nhật
          const dataSend = {
            id: data.id,
            carCatalogId: this.$route.params.id,
            groupId: this.CCGroup,
            groupFatherId: this.CCGroupFather,
          }

          this.Nv3ImageType = data.numberOfPosition
          switch (data.numberOfPosition) {
            case 1: {
              // Ảnh full màn hình
              this.nv3ImageData = await NCoreHelper.excuteGET(this, ConstantsApi.GET_DETAIL_BY_ID_CAR_CATEGORY, dataSend)
              this.$bvModal.show(this.nv3ImageId)
              break
            }
            case 2: {
              // Tiêu đề bài viết
              this.nv3TitleData = await NCoreHelper.excuteGET(this, ConstantsApi.GET_DETAIL_BY_ID_CAR_CATEGORY, dataSend)
              this.$bvModal.show(this.nv3TitleId)
              break
            }
            case 3: {
              // Nội dung bài viết
              this.nv3ContentData = await NCoreHelper.excuteGET(this, ConstantsApi.GET_DETAIL_BY_ID_CAR_CATEGORY, dataSend)
              this.$bvModal.show(this.nv3ContentId)
              break
            }
            case 4: {
              // Cột dữ liệu
              this.nv3RowDataData = await NCoreHelper.excuteGET(this, ConstantsApi.GET_DETAIL_BY_ID_CAR_CATEGORY, dataSend)
              this.$bvModal.show(this.nv3RowDataId)
              break
            }
            default: {
              // Tiêu đề bài viết
              this.$bvModal.show(this.nv3ImageId)
              break
            }
          }

          break
        }
        case 3: {
          // Xóa
          this.deleteIds = [data.id]
          this.modalContent = 'Bạn có muốn xóa không?'
          this.modalType = 'delete'
          this.$bvModal.show(this.nv3ModalDeleteId)
          break
        }
        default: {
          // Cập nhật
          break
        }
      }
    },
    handleClickAddNew(data) {
      const dataSend = {
        carCatalogId: this.$route.params.id,
        model: [{
          id: '',
          name: data.name,
          content: '',
          position: 0,
          groupId: this.CCGroup,
          groupFatherId: this.CCGroupFather,
          typeId: data.id,
          detailFileModels: [{
            id: '',
            carCatalogDetailId: '',
            fileId: '',
            filePath: '',
            fileName: '',
            name: '',
            fileContent: '',
            fileType: '',
            filePosition: 1,
          }],
        }],
      }
      this.$bvModal.hide(this.CCAddNew_id)
      switch (data.position) {
        case 1: {
          // Ảnh đại diện full màn hình
          this.nv3ImageData = dataSend
          this.Nv3ImageType = 1
          this.$bvModal.show(this.nv3ImageId)
          break
        }
        case 2: {
          // Tiêu đề bài viết
          this.nv3TitleData = dataSend
          this.Nv3ImageType = 2
          this.$bvModal.show(this.nv3TitleId)
          break
        }
        case 3: {
          // Nội dung bài viết
          this.nv3ContentData = dataSend
          this.Nv3ImageType = 3
          this.$bvModal.show(this.nv3ContentId)
          break
        }
        case 4: {
          // Cột dữ liệu
          this.nv3RowDataData = dataSend
          this.Nv3ImageType = 4
          this.$bvModal.show(this.nv3RowDataId)
          break
        }
        case 5: {
          // Chia 3 cột dữ liệu
          break
        }
        case 6: {
          // Nhiều ảnh nhỏ trong 1 hình
          this.nv3MultiSmallImageData = dataSend
          this.Nv3ImageType = 6
          this.$bvModal.show(this.nv3MultiSmallImageId)
          break
        }
        case 7: {
          // Sile hình ảnh
          break
        }
        case 8: {
          // So sánh xe
          break
        }
        case 9: {
          // Video TVC
          break
        }
        default: {
          break
        }
      }
    },
    async handClickSave(type, data) {
      const dataSend = {
        carCatalogId: this.$route.params.id,
        model: [{
          id: '',
          name: data.name,
          content: '',
          position: 0,
          groupId: this.CCGroup,
          groupFatherId: this.CCGroupFather,
          typeId: data.id,
          detailFileModels: [{
            id: '',
            carCatalogDetailId: '',
            fileId: '',
            filePath: '',
            fileName: '',
            name: '',
            fileContent: '',
            fileType: '',
            filePosition: 1,
          }],
        }],
      }
      dataSend.model = data.model
      debugger
      const iResult = await NCoreHelper.excutePUT(this, ConstantsApi.UPDATE_DETAIL_CAR_CATEGORY, dataSend, 'Cập nhật dữ liệu thành công')
      if (iResult) {
        await this.fetchDataList()
        this.$bvModal.hide(this.nv3ImageId)
        this.$bvModal.hide(this.nv3TitleId)
        this.$bvModal.hide(this.nv3ContentId)
        this.$bvModal.hide(this.nv3RowDataId)
      }
      // switch (type) {
      //   case 1: {
      //     // Ảnh đại diện full màn hình
      //     // ==> Lưu dữ liệu
      //     dataSend.model = data.model
      //     break
      //   }
      //   case 2: {
      //     // Tiêu đề bài viết
      //     dataSend.model = data.model
      //     break
      //   }
      //   case 3: {
      //     // Nội dung bài viết
      //     break
      //   }
      //   case 4: {
      //     // Chia 2 cột dữ liệu
      //     break
      //   }
      //   case 5: {
      //     // Chia 3 cột dữ liệu
      //     break
      //   }
      //   case 6: {
      //     // Nhiều ảnh nhỏ trong 1 hình
      //     break
      //   }
      //   case 7: {
      //     // Sile hình ảnh
      //     break
      //   }
      //   case 8: {
      //     // So sánh xe
      //     break
      //   }
      //   case 9: {
      //     // Video TVC
      //     break
      //   }
      //   default: {
      //     break
      //   }
      // }
      // const iResult = await NCoreHelper.excutePUT(this, ConstantsApi.UPDATE_DETAIL_CAR_CATEGORY, dataSend, 'Cập nhật dữ liệu thành công')
      // if (iResult) {
      //   await this.fetchDataList()
      //   this.$bvModal.hide(this.nv3ImageId)
      //   this.$bvModal.hide(this.nv3TitleId)
      // }
    },
    async handleClickDelete() {
      const iResult = await NCoreHelper.excuteDELETE(this, ConstantsApi.DELETE_DETAIL_CAR_CATEGORY, { model: JSON.stringify(this.deleteIds) }, 'Xóa dữ liệu thành công')
      if (iResult) {
        await this.fetchDataList()
        this.$bvModal.hide(this.nv3ModalDeleteId)
      }
    },
    // ************ chức năng xóa ****************//
    // xóa 1 người dùng
    async handleOpenModalQuestion(id, statusKey) {
      this.deleteIds = [id]
      this.statusForm = parseFloat(statusKey)
      // eslint-disable-next-line no-nested-ternary
      switch (parseFloat(statusKey)) {
        case 0: {
          this.modalContent = this.$t('Bạn có muốn xóa không?')
          break
        }
        case 3: {
          this.modalContent = this.$t('Bạn có muốn kích hoạt sử dụng dữ liệu này không ?')
          break
        }
        default: {
          this.modalContent = this.$t('Bạn có muốn ngừng sử dụng dữ liệu này không ?')
          break
        }
      }
      this.modalType = statusKey === 0 ? 'delete' : 'update_status'
      this.$bvModal.show('idModalQuestion')
    },
    // chọn hàng table
    selectRowTable(val) {
      this.deleteIds = val.selectedRows.map(e => e.id)
      this.showBtnMultiDelete = this.deleteIds.length > 0
    },
    // click xóa nhiều người dùng
    deleteItems() {
      this.modalContent = this.$t('Bạn có muốn xóa các mục đã chọn không?')
      this.$bvModal.show(this.nv3ModalDeleteId)
    },
    // =========================Hàm xử lý dữ liệu ====================================//
    getExecuteAction(type, data) {
      return NCoreHelper.GetExecuteAction(type, data)
    },
    // ==========================Hàm xem chi tiết======================================
    handleViewDetail(ids) {
      // this.$router.push({ name: 'car-catalog-overview' })
      this.$router.push({
        name: 'car-catalog-detail',
        params: { id: ids },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#users {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .search-input {
    width: 400px;
  }
}
</style>

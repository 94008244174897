<template>
  <div>
    <my-tabs
      :listTabs="listTabs"
      defaultActive="user-edit-tab-account"
      typeCss="active-full"
      @setActive="setActive"
    >
    </my-tabs>
    <overview
      :class="{'active':activeItem==='overview'}"
      :group-father-id="1"
      class="tab-pane"
    />
    <design
      :class="{'active':activeItem==='design'}"
      class="tab-pane"
      @click="openDesign"
    />
    <price
      :class="{'active':activeItem==='price'}"
      class="tab-pane"
    />
    <!--    <operate-->
    <!--      class="tab-pane"-->
    <!--      :class="{'active':activeItem==='operate'}"-->
    <!--    />-->
    <!--    <intelligent-->
    <!--      class="tab-pane"-->
    <!--      :class="{'active':activeItem==='intelligent'}"-->
    <!--    />-->
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import MyTabs from '@/components/tabs/MyTabs.vue'
import Overview from '@/views/car-manager/car-catalog/pages/components/Overview.vue'
import Design from '@/views/car-manager/car-catalog/pages/components/Design.vue'
import Price from '@/views/car-manager/car-catalog/pages/components/Price.vue'
import Operate from '@/views/car-manager/car-catalog/pages/components/Operate.vue'
import Intelligent from '@/views/car-manager/car-catalog/pages/components/Intelligent.vue'

export default {
  components: {
    BButton,
    MyTabs,
    Overview,
    Design,
    Price,
    Operate,
    Intelligent,
  },
  props: {
    dLocalStorage: {
      type: Object,
      default: _ => {
      },
    },
  },
  data() {
    return {
      activeItem: 'overview',
      listTabs: [
        {
          key: 'overview',
          title: 'Tổng quan',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'design',
          title: 'Thiết kế',
          icon: 'CloudLightningIcon',
          isDisabled: false,
        },
        {
          key: 'price',
          title: 'Phiên bản và giá',
          icon: 'SunIcon',
          isDisabled: false,
        },
        {
          key: 'operate',
          title: 'Vận hành',
          icon: 'ListIcon',
          isDisabled: false,
        },
        {
          key: 'intelligent',
          title: 'Intelligent Mobility',
          icon: 'WindIcon',
          isDisabled: false,
        },
      ],
      designData: [],
      designTotal: 10,
    }
  },
  methods: {
    setActive(tabActive) {
      this.activeItem = tabActive
    },
    async openDesign() {
      // const iResult = await NCoreHelper.excuteGET(this, DesignApi.GET_PAGING_CAR_COLOR, {
      //   search: '',
      //   pageSize: 10,
      //   pageNumber: 1,
      //   CarColorId: this.$route.params.id,
      // })
      // this.designData = iResult.pageLists
      // this.designTotal = iResult.totalRecord
    },
  },
}
</script>

<template>
  <div
    id="DetailCarCatalog"
    className="page-container-table"
  >
    <version-list />
  </div>
</template>
<script>
// eslint-disable-next-line import/no-unresolved
import {
  BButton, BTabs, BTab, BCardText, BRow, BCol, BFormTextarea, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import NCKEditor from '@/views/ndev-component/NCKEditor.vue'
import VersionList from '@/views/car-manager/car-catalog/pages/components/Version/VersionList.vue'

export default {
  components: {
    VersionList,
    NCKEditor,
    BButton,
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    BFormTextarea,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      noi_dung: '',
      checkCodeData: '',
      checkEmailData: '',
      activeItem: 'business-info',
      listTabs: [
        {
          key: 'business-info',
          title: 'Thông tin doanh nghiệp',
          icon: 'BriefcaseIcon',
          isRendered: false,
        },
        {
          key: 'business-Representative',
          title: 'Thông tin người đại diện',
          icon: 'UsersIcon',
          isDisabled: false,
        },
        {
          key: 'business-other',
          title: 'Ngành nghề kinh doanh khác',
          icon: 'PocketIcon',
          isDisabled: true,
        },
      ],
    }
  },
  computed: {
    ...mapState('business', ['dataEdit', 'alert']),
  },
  created() {
    if (this.$route.params.id) {
      this.fetchdataIdST(this.$route.params.id)
    }
  },
  methods: {
    ...mapActions('business', ['addBusiness', 'fetchdataIdST', 'checkCodeST', 'checkEmailST', 'editST']),
    data01() {
      return this.$refs.informationAndAdress.valueCodeRef
    },
    setActive(tabActive) {
      this.activeItem = tabActive
    },
    // check code
    async checkcodeFS(data) {
      const dataCheckCode = {
        code: data.code,
        id: this.$route.params.id || [],
      }
      this.checkCodeData = await this.checkCodeST(dataCheckCode)
    },
    async checkEmailFS(data) {
      const dataCheckEmail = {
        code: data.email,
        id: this.$route.params.id || [],
      }
      this.checkEmailData = await this.checkEmailST(dataCheckEmail)
    },
    async save() {
      this.$refs.rule.validate()
        .then(async success => {
          if (success) {
            const data = {
              ...this.$refs.informationAndAdress.valueData,
              ...this.$refs.representative.valueID,
            }
            const againPass = this.$refs.informationAndAdress.againPassworks
            await this.checkcodeFS(data)
            await this.checkEmailFS(data)
            // console.log(this.checkCodeData)
            // console.log(this.checkEmailData)
            if (this.$route.params.id) {
              if (this.checkCodeData === false && this.checkEmailData === false) {
                await this.editST(data)
                this.$bvToast.toast(this.alert.name, {
                  title: 'Thông báo',
                  variant: this.alert.color,
                  toaster: this.$toastPosition,
                  solid: true,
                })
                this.$router.push({ name: 'business' })
              }
            }
            if (againPass === data.password) {
              await this.addBusiness(data)
              this.$bvToast.toast(this.alert.name, {
                title: 'Thông báo',
                variant: this.alert.color,
                toaster: this.$toastPosition,
                solid: true,
              })
              this.$router.push({ name: 'business' })
            } else {
              // eslint-disable-next-line no-alert
              this.$bvToast.toast('Nhập lại mật khẩu không chính xác', {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            }
          }
        })
    },
    save01() {
      this.$router.push({ name: 'business' })
    },
  },
}
</script>

<style lang="scss">
#addBusiness {
  .add-business__button {
    padding: 40px 20px 20px 20px;
    display: flex;

    &__item {
      margin-right: 20px;
    }
  }

  .addBusiness-fix {
    padding: 0px;
  }
}

</style>

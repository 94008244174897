<template>
  <div style="padding-left: 10px;padding-right: 10px;">
    <b-row>
      <b-col
        class="mb-1"
        lg="12"
        md="12"
        sm="12"
        xl="12"
        xs="12"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          size="sm"
          variant="outline-primary"
          @click="handler_click(1)"
        >
          <feather-icon
            class="mr-50"
            icon="CloudIcon"
          />
          Chọn từ thư viện
        </b-button>
      </b-col>
      <b-col
        class="mb-1"
        lg="12"
        md="12"
        sm="12"
        xl="12"
        xs="12"
      >
        <!--        <ckeditor-->
        <!--          v-model="editorData"-->
        <!--          :editor="editor"-->
        <!--          :config="editorConfig"-->
        <!--        ></ckeditor>-->
        <ckeditor
          :id="idCkeditor"
          :ref="commonRef"
          v-model="quillContent"
          :config="editorConfig"
          :editor="editor"
          @ready="onReady"
          @focus="onEditorFocus"
        ></ckeditor>

        <library-modal
          :id-library-model="idLibraryModel"
          @handleCloseLibraryModal="handleCloseLibraryModal"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

// eslint-disable-next-line import/order
import LibraryModal from '@/views/ndev-component/library/LibraryModal.vue'
import 'ckeditor5-vuejs/dist/ckeditor.css'
// eslint-disable-next-line import/order
import { CKEditor, UploaderPlugin } from 'ckeditor5-vuejs'

export default {
  components: {
    CKEditor,
    UploaderPlugin,
    LibraryModal,
  },
  props: {
    idLibraryModel: {
      type: String,
      default: '123',
    },
    quillTitle: {
      type: String,
      default: '',
    },
    quillRequired: {
      type: Boolean,
      default: true,
    },
    quillId: {
      type: String,
      default: '',
    },
    quillContent: {
      type: String,
      default: 'Nhập nội dung bài viết',
    },
    idCkeditor: {
      type: String,
      default: 'idCkeditor',
    },
  },
  data() {
    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      // [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button
      ['image'],
      ['video'],
    ]
    return {
      editorData: 'Nhập dữ liệu ....',
      post: {
        content: 'zzzzzz',
      },
      editorConfig: {
        toolbar: {
          viewportTopOffset: 50, // <-- height of fixed header
          items: [
            'heading', '|', 'fontsize', 'fontfamily', '|', 'bold', 'italic', 'underline', 'strikethrough', 'highlight', '|', 'alignment', '|',
            'numberedList', 'bulletedList', '|', 'link', 'blockquote', 'imageUpload', 'insertTable', 'mediaEmbed', '|', 'undo', 'redo',
            'affBox', 'insertMedia', 'spinText',
          ],
        },
        allowedContent: true,
        // extraAllowedContent: '*(*);*{*}',
        extraPlugins: null,
        autosave: {
          waitingTime: 60000,
          save: editor => {
            this.autosave()
          },
        },
      },
      content: '',
      commonRef: {
        type: Object,
        default: _ => {
        },
      },
      modelSend: {
        id: '',
        name: '',
        content: '',
        position: this.valueNumber,
        groupId: this.groupId,
        groupFatherId: this.groupFatherId,
        typeId: 'EB2244FB-98F7-4B4D-98B4-47DE46164F53',
        detailFileModels: null,
      },
    }
  },
  watch: {
    idLibraryModel: {
      deep: true,
      handler(val) {
        this.idLibraryModel = val
      },
    },
    quillContent: {
      deep: true,
      handler(val) {
        this.func_dataRoot_change(val)
      },
    },
  },
  created() {
    this.editorConfig.extraPlugins = [UploaderPlugin(this.onUploadImage)]
  },
  mounted() {
    // CKEditor.create(document.querySelector('#editor'), editorConfig).then(
    //   editor => {
    //     this.editor = editor
    //     this.editor.model.document.on('change', () => {
    //       this.updateContent(this.editor.getData())
    //     })
    //   },
    // )
  },
  methods: {
    ...mapActions('libraryStore', ['libraryStore_action_SetBreadCom']),
    imageHandler() {
      this.libraryStore_action_SetBreadCom([
        {
          id: 0,
          idFolder: '0',
          name: 'Thư mục gốc',
        },
      ])
      this.$bvModal.show('idLibraryModel')
    },
    async onUploadImage(loader, cancelSource) {
      // upload file
      return { source_url: 'https://uploaded_image_url' }
    },
    computed: {
      instance() {
        return CKEditor.instances[this.idCkeditor]
      },
    },
    mounted() {
    },
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui.getEditableElement()
        .parentElement
        .insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement(),
        )
    },
    autosave() {
      // (async () => {
      //
      // })
    },
    func_dataRoot_change(val) {
      this.post.content = this.quillContent
    },
    handler_click() {
      this.$bvModal.show(this.idLibraryModel)
    },
    handleCloseLibraryModal(val) {
      // A reference to the editor editable element in the DOM.
      const domEditableElement = document.querySelector('.ck-editor__editable')

      // Get the editor instance from the editable element.
      const editorInstance = domEditableElement.ckeditorInstance

      // Use the editor instance API.
      // editorInstance.setData('<p>Hello world!<p>')
      editorInstance.insertHtml('<p>Hello world!<p>')
      editorInstance.insertText('<p>Hello world!<p>')
      this.$bvModal.hide(this.idLibraryModel)
    },
  },
}
</script>

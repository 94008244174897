<template>
  <b-row>
    <b-col
      xs="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
      class="font-weight-600 mb-1"
    >
      {{ valueNumber }}. Tiêu đề <span
        :hidden="resultRequired"
        class="nRequired"
      >(*)</span>
    </b-col>
    <b-col
      xs="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
      class="font-weight-600 mb-1"
    >
      <input
        id="txtTitle"
        v-model="modelSend.name"
        :placeholder="resultPlace"
        autocomplete="off"
        autofocus
        class="form-control"
      />
    </b-col>
  </b-row>
</template>
<script>
import {
  BButton, BTabs, BTab, BCardText, BRow, BCol, BFormTextarea, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
import LibraryModal from '@/views/ndev-component/library/LibraryModal.vue'
import NViewImage from '@/views/ndev-component/news-data-manager/NViewImage.vue'

export default {
  components: {
    NViewImage,
    LibraryModal,
    BButton,
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    BFormTextarea,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    valueNumber: {
      type: Number,
      default: 1,
    },
    resultPlace: {
      type: String,
      default: '',
    },
    resultRequired: {
      type: Boolean,
      default: true,
    },
    groupId: {
      type: Number,
      default: 1,
    },
    groupFatherId: {
      type: Number,
      default: 1,
    },
    dDataRoot: {
      type: Object,
      default: _ => {
      },
    },
  },
  data() {
    return {
      idTitleModal: 'idTitleModal',
      modelSend: {
        id: '',
        name: '',
        content: '',
        position: this.valueNumber,
        groupId: this.groupId,
        groupFatherId: this.groupFatherId,
        typeId: '0589CF79-B4A6-4711-92E4-2B33FA70DD43',
        detailFileModels: null,
      },
    }
  },
  watch: {
    dDataRoot: {
      deep: true,
      handler(val) {
        this.func_dataRoot_change(val)
      },
    },
  },
  mounted() {
    this.func_dataRoot_change(this.dDataRoot)
  },
  created() {
  },
  methods: {
    func_dataRoot_change(val) {
      if (this.dDataRoot.groupId === this.groupId && this.dDataRoot.groupFatherId === this.groupFatherId) {
        this.modelSend = this.dDataRoot
        // eslint-disable-next-line prefer-destructuring
        this.detailFileModels = null
      }
    },
  },
}
</script>

<template>
  <b-row>
    <b-col
      xs="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
      class="font-weight-600 mb-1"
    >
      {{ valueNumber }}. Ảnh đại diện full màn hình
    </b-col>
    <b-col
      xs="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
      class="font-weight-600 mb-1"
    >
      <span>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          size="sm"
          @click="handler_overview_button(1)"
        >
          <feather-icon
            icon="CloudIcon"
            class="mr-50"
          />
          Chọn ảnh
        </b-button>
      </span>
      <span>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-danger"
          class="ml-1"
          size="sm"
          @click="handler_overview_button(2)"
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-50"
          />
          Xem ảnh
        </b-button>
      </span>
      <span class="ml-1">{{ $t(detailFileModels.fileName) }}</span>
    </b-col>
    <library-modal
      :id-library-model="idLibraryModel_ImageFullScreen"
      @handleCloseLibraryModal="handleCloseLibraryModal"
    />
    <n-view-image
      :id-modal="idLibraryModel_ViewImage"
      :data-send="detailFileModels"
    />
  </b-row>
</template>
<script>
import {
  BButton, BTabs, BTab, BCardText, BRow, BCol, BFormTextarea, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import LibraryModal from '@/views/ndev-component/library/LibraryModal.vue'
import NViewImage from '@/views/ndev-component/news-data-manager/NViewImage.vue'
import { alertError, alertError2 } from '@/views/ndev-component/constants/NCoreHelper'

export default {
  components: {
    NViewImage,
    LibraryModal,
    BButton,
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    BFormTextarea,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    valueNumber: {
      type: Number,
      default: 1,
    },
    groupId: {
      type: Number,
      default: 1,
    },
    groupFatherId: {
      type: Number,
      default: 1,
    },
    dDataRoot: {
      type: Object,
      default: _ => {
      },
    },
  },
  data() {
    return {
      idLibraryModel_ImageFullScreen: 'idLibraryModel_ImageFullScreen',
      idLibraryModel_ViewImage: 'idLibraryModel_ViewImage',
      detailFileModels: {
        id: '',
        carCatalogDetailId: '',
        fileId: '',
        filePath: '',
        fileName: '',
        fileType: '',
      },
      modelSend: {
        id: '',
        name: '',
        content: '',
        position: this.valueNumber,
        groupId: this.groupId,
        groupFatherId: this.groupFatherId,
        typeId: '3E8D0A58-6876-4A68-B30E-F7C83959F2F6',
        detailFileModels: this.detailFileModels,
      },
    }
  },
  watch: {
    dDataRoot: {
      deep: true,
      handler(val) {
        this.func_dataRoot_change(val)
      },
    },
  },
  mounted() {
    this.func_dataRoot_change(this.dDataRoot)
  },
  methods: {
    ...mapActions('libraryStore', ['libraryStore_action_ResetBreadCom']),
    handler_overview_button(type) {
      if (type === 1) {
        this.libraryStore_action_ResetBreadCom()
        this.$bvModal.show(this.idLibraryModel_ImageFullScreen)
      } else {
        if (this.detailFileModels.filePath === '') {
          alertError2(null, 'Bạn chưa chọn hình ảnh để xem')
          return
        }
        this.$bvModal.show(this.idLibraryModel_ViewImage)
      }
    },
    handleCloseLibraryModal(val) {
      // eslint-disable-next-line no-empty
      if (val === null || val === undefined) {

      } else {
        this.detailFileModels = {
          fileName: val.name,
          fileId: val.id,
          filePath: val.filePath,
          fileType: val.fileType,
        }

        this.modelSend.detailFileModels = []
        this.modelSend.detailFileModels.push(this.detailFileModels)
      }
      this.$bvModal.hide(this.idLibraryModel_ImageFullScreen)
    },
    func_dataRoot_change(val) {
      if (this.dDataRoot.groupId === this.groupId && this.dDataRoot.groupFatherId === this.groupFatherId) {
        this.modelSend = this.dDataRoot
        // eslint-disable-next-line prefer-destructuring
        this.detailFileModels = this.dDataRoot.detailFileModels[0]
      }
    },
  },
}
</script>

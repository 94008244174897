<template>
  <div
    id="users"
    class="page-container-table"
  >
    <!--phần header-->
    <n-button-header
      :content-btn-add="'Thêm phiên bản'"
      @clickAdd="handleOpenModalCreate"
      @search="search($event)"
    />
    <!--Phần bảng-->
    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataRows || []"
      :sort-options="{
        enabled: false,
      }"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column:Tên phiên bản và giá-->
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <b-media>
            <template #aside>
              <user-avatar :data="props.row" />
            </template>
            <span class="font-weight-bold text-name">{{ props.row.name }}</span>
          </b-media>
        </span>

        <!-- Column: Giá bán đề xuất -->
        <span v-else-if="props.column.field === 'priceName'">
          <span class="text-nowrap text-right">{{ props.row.priceName }}</span>
        </span>
        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'actionFunction'">
          <span
            @click="handleOpenModalUpdate(props.row.id)"
          >
            <feather-icon
              icon="EditIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="handleOpenModalQuestion(props.row.id, 0)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="handleViewDetail(props.row.id)"
          >
            <feather-icon
              icon="EyeIcon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :total-items="totalRecord"
      :current-page="urlQuery.currentPage"
      @pageClick="handlePageClick"
    />

    <!-- modal thêm mới -->
    <version-event
      id-modal-create-car-catalog="modalCreate"
      :type="modalType"
      :data-car-catalog-detail="dataCarCatalogDetail"
      @handleCreateCarCategory="handleCreateCarCategory"
    />

    <!-- Modal xóa-->
    <modal-delete-no-param
      id-modal-delete-no-param="idModalQuestion"
      :type="modalType"
      :content-tile="modalContent"
      @accept="handleRunQuestionCarCategory"
    />
  </div>
</template>

<script>
import { formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters, mapState } from 'vuex'
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { axiosApiInstance } from '@core/auth/jwt/axiosApiInstance'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import ModalCreateCarCatalog from '@/views/car-manager/car-catalog/pages/components/ModalCreateCarCatalog.vue'
import ConstantsApi from '@/views/car-manager/car-catalog/constants/ConstantsApi'
import NFormStatus from '@/views/ndev-component/NFormStatus.vue'
import ModalDeleteNoParam from '@/views/ndev-component/ModalDeleteNoParam.vue'
import NAction from '@/views/ndev-component/NAction.vue'
import NActionCarCatolog from '@/views/ndev-component/action/NActionCarCatolog.vue'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import NButtonHeader from '@/views/ndev-component/component/NButtonHeader.vue'
import VersionEvent from '@/views/car-manager/car-catalog/pages/components/Version/VersionEvent.vue'

export default {
  components: {
    VersionEvent,
    NButtonHeader,
    NActionCarCatolog,
    NAction,
    NFormStatus,
    ModalCreateCarCatalog,
    ModalDeleteNoParam,
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    BDropdown,
    BDropdownItem,
    ButtonComponent,
    ConfirmModal,
    ButtonAllHeader,
    UserAvatar,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      currentPage: 1,
      columns: [
        {
          label: 'Tên xe',
          field: 'name',
        },
        {
          label: 'Giá bán đề xuất',
          field: 'priceName',
        },
        {
          label: 'Chức năng',
          field: 'actionFunction',
          thClass: 'custom-action-table-class',
          tdClass: 'text-center',
        },
      ],
      urlQuery: {
        search: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '', // thông báo xóa
      showBtnMultiDelete: false,
      deleteIds: [], // ID xóa
      isActive: false,
      userId: '',
      dataRows: [], // dữ liệu bảng
      totalRecord: 10, // tổng số trang
      dataCarCatalogDetail: [], // Dữ liệu chi tiết
      modalType: '',
      mainKey: '',
      statusForm: 0,
    }
  },
  computed: {
    ...mapState('business', ['alert']),
  },
  mounted() {
    // this.doFetchListUsers(this.urlQuery)
  },
  created() {
    this.fetchDataList()
  },
  methods: {
    // =========================hàm mới tạo ====================================//
    async fetchDataList() {
      this.urlQuery.carCatalogId = this.$route.params.id
      const iResult = await NCoreHelper.excuteGET(this, ConstantsApi.GET_PAGING_CAR_CATEGORY_VERSION, this.urlQuery)
      this.dataRows = iResult.pageLists
      this.totalRecord = iResult.totalRecord
    },
    // -----phần tìm kiếm
    async search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        await this.fetchDataList()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        await this.fetchDataList()
      }
    },
    // -----phần phân trang
    async handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      await this.fetchDataList()
    },
    // ---- Open model -----
    handleOpenModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show('modalCreate')
    },
    async handleOpenModalUpdate(id) {
      this.modalType = 'edit'
      this.$bvModal.show('modalCreate')
      this.mainKey = id
      // ========================Gọi API lấy dữ liệu chi tiết====================================
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_CAR_CATEGORY_VERSION}${id}`)
      this.dataCarCatalogDetail = data
    },
    // ************ chức năng xóa ****************//
    // xóa 1 người dùng
    async handleOpenModalQuestion(id, statusKey) {
      this.deleteIds = [id]
      this.statusForm = parseFloat(statusKey)
      // eslint-disable-next-line no-nested-ternary
      switch (parseFloat(statusKey)) {
        case 0: {
          this.modalContent = this.$t('Bạn có muốn xóa không?')
          break
        }
        case 3: {
          this.modalContent = this.$t('Bạn có muốn kích hoạt sử dụng dữ liệu này không ?')
          break
        }
        default: {
          this.modalContent = this.$t('Bạn có muốn ngừng sử dụng dữ liệu này không ?')
          break
        }
      }
      this.modalType = statusKey === 0 ? 'delete' : 'update_status'
      this.$bvModal.show('idModalQuestion')
    },
    // chọn hàng table
    selectRowTable(val) {
      this.deleteIds = val.selectedRows.map(e => e.id)
      this.showBtnMultiDelete = this.deleteIds.length > 0
    },
    // click xóa nhiều người dùng
    deleteItems() {
      this.modalContent = this.$t('Bạn có muốn xóa các mục đã chọn không?')
      this.$bvModal.show('idModalQuestion')
    },
    // =========================Hàm xử lý dữ liệu ====================================//
    getExecuteAction(type, data) {
      return NCoreHelper.GetExecuteAction(type, data)
    },
    async handleCreateCarCategory(val) {
      const payload = {
        name: val.name,
        price: val.price === 0 ? 0 : parseFloat(val.price.replace(/,/g, '')),
        truocBa: val.truocBa === 0 ? 0 : parseFloat(val.truocBa.replace(/,/g, '')),
        dangKy: val.dangKy === 0 ? 0 : parseFloat(val.dangKy.replace(/,/g, '')),
        kiemDinh: val.kiemDinh === 0 ? 0 : parseFloat(val.kiemDinh.replace(/,/g, '')),
        BaoHiemVatChat: val.baoHiem === 0 ? 0 : parseFloat(val.baoHiem.replace(/,/g, '')),
        DuongBo: val.duongBo === 0 ? 0 : parseFloat(val.duongBo.replace(/,/g, '')),
        TrachNhiemDanSu: val.trachNhiem === 0 ? 0 : parseFloat(val.trachNhiem.replace(/,/g, '')),
        Image: val.image,
        carCatalogId: this.$route.params.id,
      }
      if (this.modalType === 'edit') {
        payload.id = this.mainKey
        const iResult = await NCoreHelper.excutePUT(this, ConstantsApi.EDIT_CAR_CATEGORY_VERSION, payload, 'Cập nhật phiên bản và giá thành công')
        if (iResult) {
          await this.fetchDataList()
          this.$bvModal.hide('modalCreate')
        }
      }
      if (this.modalType === 'add') {
        const iResult = await NCoreHelper.excutePOST(this, ConstantsApi.CREATE_CAR_CATEGORY_VERSION, payload, 'Thêm mới phiên bản và giá thành công')
        if (iResult) {
          await this.fetchDataList()
          this.$bvModal.hide('modalCreate')
        }
      }
    },

    async handleRunQuestionCarCategory() {
      switch (this.modalType) {
        case 'delete': {
          const iResult = await NCoreHelper.excuteDELETE(this, ConstantsApi.DELETE_CAR_CATEGORY_VERSION, { params: { model: JSON.stringify(this.deleteIds) } }, 'Xóa phiên bản và giá thành công')
          if (iResult) {
            await this.fetchDataList()
            this.$bvModal.hide('modalCreate')
          }
          break
        }
        default: {
          break
        }
      }
    },
    // ==========================Hàm xem chi tiết======================================
    handleViewDetail(ids) {
      // this.$router.push({ name: 'car-catalog-overview' })
      this.$router.push({
        name: 'car-catalog-version-version',
        params: { id: ids },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#users {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .search-input {
    width: 400px;
  }
}
</style>

<template>
  <b-card-code>
    <b-row
      v-for="items in dConstruct"
      :key="items.id"
    >
      <b-col
        v-if="items.id === 1"
        :hidden="items.isHidden"
        xs="12"
        sm="8"
        md="8"
        lg="9"
        xl="9"
        class="font-weight-600 mb-1"
      >
        - Ảnh đại diện full màn hình
      </b-col>
      <b-col
        v-if="items.id === 1"
        :hidden="items.isHidden"
        xs="12"
        sm="4"
        md="4"
        lg="3"
        xl="3"
        class="font-weight-600 mb-1 text-right"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-danger"
          size="sm"
          @click="handler_delete_step(items)"
        >
          <feather-icon
            icon="ScissorsIcon"
            class="mr-50"
          />
          Xóa bước dữ liệu
        </b-button>
      </b-col>
      <b-col
        v-if="items.id === 1"
        :hidden="items.isHidden"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        class="font-weight-600 mb-1"
      >
        <span>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            size="sm"
            @click="handler_overview_button(1)"
          >
            <feather-icon
              icon="CloudIcon"
              class="mr-50"
            />
            Chọn ảnh
          </b-button>
        </span>
        <span>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            class="ml-1"
            size="sm"
            @click="handler_overview_button(1)"
          >
            <feather-icon
              icon="EyeIcon"
              class="mr-50"
            />
            Xem ảnh
          </b-button>
        </span>
        <span class="ml-1">ảnh 01.jpg</span>
      </b-col>
      <b-col
        v-if="items.id === 2"
        :hidden="items.isHidden"
        xs="12"
        sm="8"
        md="8"
        lg="9"
        xl="9"
        class="font-weight-600 mb-1"
      >
        - Tiêu đề
      </b-col>
      <b-col
        v-if="items.id === 2"
        :hidden="items.isHidden"
        xs="12"
        sm="4"
        md="4"
        lg="3"
        xl="3"
        class="font-weight-600 mb-1 text-right"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-danger"
          size="sm"
          @click="handler_delete_step(items)"
        >
          <feather-icon
            icon="ScissorsIcon"
            class="mr-50"
          />
          Xóa bước dữ liệu
        </b-button>
      </b-col>
      <b-col
        v-if="items.id === 2"
        :hidden="items.isHidden"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        class="font-weight-600 mb-1"
      >
        <input
          id="InputHelp"
          placeholder="Nhập tên loại xe ..."
          autocomplete="off"
          class="form-control"
        />
      </b-col>
      <b-col
        v-if="items.id === 3"
        :hidden="items.isHidden"
        xs="12"
        sm="8"
        md="8"
        lg="9"
        xl="9"
        class="font-weight-600 mb-1"
      >
        - Nội dung
      </b-col>
      <b-col
        v-if="items.id === 3"
        :hidden="items.isHidden"
        xs="12"
        sm="4"
        md="4"
        lg="3"
        xl="3"
        class="font-weight-600 mb-1 text-right"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-danger"
          size="sm"
          @click="handler_delete_step(items)"
        >
          <feather-icon
            icon="ScissorsIcon"
            class="mr-50"
          />
          Xóa bước dữ liệu
        </b-button>
      </b-col>
      <b-col
        v-if="items.id === 3"
        :hidden="items.isHidden"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        class="font-weight-600"
      >
      </b-col>
    </b-row>
  </b-card-code>
</template>
<script>
import {
  BButton, BTabs, BTab, BCardText, BRow, BCol, BFormTextarea, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import NQuillEditor from '@/views/ndev-component/NQuillEditor.vue'

export default {
  components: {
    BCardCode,
    NQuillEditor,
    BButton,
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    BFormTextarea,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    dEmitActive: {
      type: Object,
      default: _ => {
      },
    },
  },
  data() {
    return {
      dConstruct: [
        {
          id: 1,
          sNumber: 1,
          isHidden: true,
        },
        {
          id: 2,
          sNumber: 2,
          isHidden: true,
        },
        {
          id: 3,
          sNumber: 3,
          isHidden: true,
        },
      ],
    }
  },
  created() {
    const iStruct = this.dConstruct.find(x => x.id === this.dEmitActive.idNewsData)
    iStruct.isHidden = false
    iStruct.sNumber = this.dEmitActive.sNumber
  },
  methods: {
    handler_delete_step(val) {
      this.$emit('handler_delete_step', val)
    },
  },
}
</script>

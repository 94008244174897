<template>
  <b-modal
    :id="idModal"
    size="sm"
    title="Chọn chức năng"
    cancel-title="Quay về"
    cancel-variant="outline-primary"
    centered
    hide-footer="true"
    hide-header="true"
    @hidden="resetState"
  >
    <b-form class="content-form">
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
          class="text-center font-weight-600"
          style="color: blue;text-transform: uppercase"
        >
          DANH SÁCH LOẠI NỘI DUNG
        </b-col>
        <b-col
          xs="12"
          sm="6"
          ms="6"
          lg="6"
          xl="6"
        >
          <b-img
            :src="filePath"
            fluid
            :alt="fileName"
            class="mt-1"
            style="width: 100%;height: auto;object-fit: cover;"
          />
        </b-col>
        <b-col
          xs="12"
          sm="6"
          ms="6"
          lg="6"
          xl="6"
        >
          <b-row>
            <b-col
              v-for="items in dataList"
              :key="items.id"
              xs="12"
              sm="12"
              md="12"
              xl="12"
              class="text-left"
            >
              <b-button
                v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                size="sm"
                class="form-control nFolder_Title mt-1"
                variant="outline-primary"
                @click="handleCloseModal(false, items)"
              >
                <feather-icon
                  :icon="items.icon"
                  class="mr-50"
                />
                {{ items.name }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          xs="12"
          sm="12"
          ms="12"
          lg="12"
          xl="12"
          class="text-left"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="primary"
            class="mr-20"
            @click="handleCloseModal(true, null)"
          >
            <feather-icon
              icon="LogOutIcon"
              class="mr-50"
            />
            Quay về
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import NewsDataConstantsApi from '@/views/ndev-component/constants/NewsDataConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    BForm,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  props: {
    groupSend: {
      type: Number,
      default: 1,
    },
    dataList: {
      type: Object,
      default: _ => {
      },
    },
    idModal: {
      type: String,
      default: 'idModalNNewsDataGroup',
    },
  },
  data() {
    return {
      urlQuery: {
        pageSize: 0,
        pageNumber: 0,
        search: '',
        groupId: this.groupSend,
      },
      filePath: '',
      fileName: '',
    }
  },
  created() {
    this.CallListNewsDataGroup()
  },
  methods: {
    async CallListNewsDataGroup() {
      const iResult = await NCoreHelper.excuteGET(this, NewsDataConstantsApi.NEWS_DATA_GROUP_DATA, this.urlQuery)
      this.dataList = iResult.pageLists
    },
    resetState() {

    },
    handleCloseModal(isCloseModal, data) {
      if (!isCloseModal) {
        this.$bvModal.hide(this.idModal)
      }
      this.$bvModal.hide(this.idModal)
      this.$emit('handleCloseModal', data)
    },
  },
}
</script>
<style lang="scss">
.handleSize {
  cursor: pointer;
}

.handleSize:hover {
  cursor: pointer;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container-table",attrs:{"id":"DetailCarCatalog"}},[_c('b-row',{staticStyle:{"padding-left":"10px","padding-bottom":"10px"}},[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12","xs":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.handleOpenModal(1)}}},[_vm._v(" Thêm mới ")])],1)],1),_c('vue-good-table',{ref:"user-table",attrs:{"columns":_vm.columns,"rows":_vm.dataRows || [],"select-options":{
      enabled: true,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    },"sort-options":{
      enabled: false,
    },"style-class":"vgt-table"},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',{staticClass:"text-nowrap"},[_c('b-media',{scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('user-avatar',{attrs:{"data":props.row}})]},proxy:true}],null,true)},[_c('span',{staticClass:"font-weight-bold text-name"},[_vm._v(_vm._s(props.row.name))])])],1):(props.column.field === 'statusName')?_c('span',[_c('n-form-status',{attrs:{"status-id":props.row.statusId,"status-name":props.row.statusName}})],1):(props.column.field === 'actionFunction')?_c('span',[_c('span',{on:{"click":function($event){return _vm.handleOpenModal(2, props.row.id)}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"EditIcon","size":"18"}})],1),_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.handleOpenModal(3, props.row.id)}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"Trash2Icon","size":"18"}})],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"current-page":_vm.urlQuery.currentPage,"total-items":_vm.totalRecord},on:{"pageClick":_vm.handlePageClick}}),_c('modal-delete-no-param',{attrs:{"content-tile":_vm.modalContent,"id-modal-delete-no-param":_vm.nv3ModalDeleteId,"type":_vm.modalType},on:{"accept":_vm.handleClickDelete}}),_c('design-event',{attrs:{"design-event-id":_vm.designEventProp.id,"design-event-title":_vm.designEventProp.title,"design-event-data":_vm.dataSendUpdate},on:{"submitEvent":_vm.submitEvent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-row>
    <b-col
      xs="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
    >
      <c-c-list
        :c-c-list_id="'ccList_CongNghe'"
        :c-c-add-new_-group="1"
        :c-c-group="2"
        :c-c-group-father="1"
        :c-c-add-new_id="'ccAddNewCongNghe'"
        :nv3-modal-delete-id="'delete_ccList_CongNghe'"
        :library-modal-id="'libraryModalId_CongNghe'"
        :nv3-title-id="'nv3TitleId_CongNghe'"
        :nv3-content-id="'nv3ContentId_CongNghe'"
        :nv3-image-id="'nv3ImageId_CongNghe'"
        :nv3-content-library-id="'nv3ContentLibraryId_CongNghe'"
        :quill-content-v3-id="'quillContentV3Id_CongNghe'"
        :nv3-row-data-id="'nv3RowDataId_CongNghe'"
        :nv3-multi-small-image-library-view-id="'nv3MultiSmallImageLibraryViewId_CongNghe'"
        :nv3-multi-small-image-id="'nv3MultiSmallImageId_CongNghe'"
        :nv3-multi-small-image-library-id="'nv3MultiSmallImageLibraryId_CongNghe'"
        :quill-multi-small-image-v3-id="'nv3MultiSmallImageQuillId_CongNghe'"
      />
    </b-col>
  </b-row>
</template>
<script>
// eslint-disable-next-line import/no-unresolved
import {
  BButton, BCardText, BCol, BFormInput, BFormTextarea, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import CCList from '@/views/car-manager/car-catalog/pages/components/CCList.vue'

export default {
  components: {
    CCList,
    BCardCode,
    BButton,
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    BFormTextarea,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    dStep: {
      type: Number,
      default: 0,
    },
    dStepStructure: {
      type: Object,
      default: _ => {
      },
    },
    dDataRoot: {
      type: Object,
      default: _ => {
      },
    },
    dGroup: {
      type: Number,
      default: 1,
    },
    dGroupFather: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    setActive(tabActive) {
      this.activeItem = tabActive
    },
    getDataRoot(val) {
      const iResult = this.dDataRoot.model.find(x => x.position === val)
      return iResult
    },
  },
}
</script>
